import React,{useState} from "react";
import { Link } from "react-router-dom";
import { useGetGalleryQuery } from "../../redux/galleryApi";
import GalleryDescriptionModal from "../partial/GalleryDescriptionModal";

function Gallery() {
  const {data}=useGetGalleryQuery()
  const [showModal, setShowModal] = useState(false)
  const [currentRecord, setCurrentRecord] = useState({})

  const handleModal=(record)=>{
    setCurrentRecord(record)
    setShowModal(true)
  }

  return (
    <div className=" p60 pb-0 " id="token">
      <div className="container">
        <h2 className="  mb-4 hadding text-center">Asset Gallery </h2>
        <div className="row">
          {data?.slice(0,4)?.map((list)=>{
            return (
              <div className="col-lg-3  mb-md-4" key={list.id}>
              <div className="text-center">
                <img
                  className="img-fluid br10"
                  src={list.image}
                  alt="cm"
                />
                <h6 className="mt-1">{list.title.length>30?`${list.title?.slice(0,30)}...`:list.title}</h6>
                <button className="btn btn-primary" onClick={()=>{handleModal(list)}}>Quick View</button>
              </div>
            </div>
            )
          })}
        </div>
        <div className="text-center mt-3">
          <Link  className="btn btn-primary" to="/gallery">View More</Link>
        </div>
      </div>
      <GalleryDescriptionModal setShowModal={setShowModal} showModal={showModal} currentRecord={currentRecord}/>
    </div>
  );
}

export default Gallery;
