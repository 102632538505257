import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";

function Header() {
  const navigate=useNavigate()
  const { authenticated, logout } = useAuth();

  const userlogout = () => {
    localStorage.clear();
    logout();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/assets/img/logo.png" alt="header-Logo" className="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
        >
          <span className="icon-bar">
            <i className="fa fa-bars fa-2x"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ms-auto line">
            <li className="nav-item">
              <a className="nav-link" href="/#" >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#about">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#QFS">
                QFS
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/members">
                members
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="nav-link" to="/elite-sciences">
                Elite Sciences
              </Link>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href="/#Tokenomics">
                Tokenomics
              </a>
            </li>

            <li className="nav-item ">
              <a className="nav-link" href="/#Contact" >
                Contact Us
              </a>
            </li>
            <li className="nav-item ms-md-4">
              {authenticated?<button className="btn" onClick={()=>userlogout()}>Logout</button>:<Link className="btn" to="/login" >
                Login
              </Link>}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
