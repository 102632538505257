import React from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function EliteDescriptionModal(props) {

    const handleClose=()=>{
      props?.setShowDescription(false)
    }

  return (
    <>
     <Modal show={props.showDescription} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          {" "}
          <Modal.Title >Description</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html:props?.description }} className={props.language_id=="Arabic"?"text-end":""}>
          
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
   
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EliteDescriptionModal