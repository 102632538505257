//================= Contact =============
export const addContactApi="/add-contact"

//================== Members =============
export const getMembersApi="/get-members"

//============== Gallery ===============
export const getGalleryApi="/get-user-gallery"

//=============== Elite Science ===============
export const getHomeEliteScienceApi="/get-home-elite-science"
export const getFilterEliteScience="/get-filter-elite-science"
export const getEliteScienceByIdApi="/get-elite-science-by-id"
export const eliteLikeDisLikeApi="/elite-science-like-dislike"
export const eliteAddCommentApi="/add-user-comment"
export const eliteAddReplyApi="/add-user-reply"
export const getEliteUserCommentApi="/get-elite-user-comment"
export const getLanguageBytopicIdApi="/get-language-topic-id"
export const addEliteShareApi="/add-elite-share"

//================ Science Box =================
export const getScienceBoxApi="/get-sciencebox"

//================ topic ===================
export const getTopicByScienceBoxApi="/get-topicsciencebox"

//================== auth =================
export const staffLoginApi="/staff-login"
export const userLoginApi="/user-login"