import { staffLoginApi, userLoginApi } from "../components/constant/api";
import { myApi } from "./api";

export const userApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setStaffLogin: builder.mutation({
      query: (post) => ({
        url: staffLoginApi,
        method: "POST",
        body:post
      }),
    }),
    setUserLogin: builder.mutation({
      query: (post) => ({
        url: userLoginApi,
        method: "POST",
        body:post
      }),
    }),
  }),
});

export const {useSetStaffLoginMutation,useSetUserLoginMutation} = userApi;