import React from "react";
import { Link } from "react-router-dom";
import { useGetMemberQuery } from "../../redux/memberApi";

function Members() {
  const {data}=useGetMemberQuery()
  return (
    <div className="game p60 bg_blar">
      <div className="container">
        <h2 className="text-center mb-2 hadding">Leadership & Members</h2>
        
        <div className="row">
          {data?.slice(0,4)?.map((list)=>{
            return (
              <div className="col-md-3 col-sm-6 mb-4 " key={list.id}>
            
              <div className="choose-item">
                <img src={list.image} className="team_img" />
                <h4>{list.name}</h4>
                <p>{list.position}</p>
              </div>
            </div>
            )
          })}
        </div>
        <div className="text-center mt-3">
          <Link className="btn btn-primary" to="/members">View More</Link>
        </div>
      </div>
    </div>
  );
}

export default Members;
