import { addEliteShareApi, eliteAddCommentApi, eliteAddReplyApi, eliteLikeDisLikeApi, getEliteScienceByIdApi, getEliteUserCommentApi, getFilterEliteScience, getHomeEliteScienceApi, getLanguageBytopicIdApi, getScienceBoxApi, getTopicByScienceBoxApi } from "../components/constant/api";
import { myApi } from "./api";

export const eliteScienceApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getHomeEliteScience: builder.query({
      query: () => ({
        url: getHomeEliteScienceApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getScienceBox: builder.query({
      query: () => ({
        url: getScienceBoxApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getTopicByscienceBox: builder.mutation({
      query: (post) => ({
        url: getTopicByScienceBoxApi+"/"+post.id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getLanguageByTopic: builder.mutation({
      query: (post) => ({
        url: getLanguageBytopicIdApi+"/"+post.id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getFilterEliteScience: builder.mutation({
      query: (post) => ({
        url: getFilterEliteScience,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getEliteScienceById: builder.mutation({
      query: (post) => ({
        url: getEliteScienceByIdApi+"/"+post.id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? {} : {};
      },
    }),
    setEliteLikeDislike: builder.mutation({
      query: (post) => ({
        url: eliteLikeDisLikeApi,
        method: "POST",
        body:post
      }),
     
    
    }),
    setEliteComment: builder.mutation({
      query: (post) => ({
        url: eliteAddCommentApi,
        method: "POST",
        body:post
      }),
    }),
    setEliteReply: builder.mutation({
      query: (post) => ({
        url: eliteAddReplyApi,
        method: "POST",
        body:post
      }),
    }),
    getEliteUserComment: builder.mutation({
      query: (post) => ({
        url: getEliteUserCommentApi+"/"+post.id,
        method: "Get",
      
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? []: [];
      },
    }),
    setEliteShare: builder.mutation({
      query: (post) => ({
        url: addEliteShareApi,
        method: "POST",
        body:post
      }),
     
    
    }),
  }),
});

export const {useGetHomeEliteScienceQuery,useGetScienceBoxQuery,useGetTopicByscienceBoxMutation,useGetFilterEliteScienceMutation,useGetEliteScienceByIdMutation,useSetEliteLikeDislikeMutation,useSetEliteCommentMutation,useGetEliteUserCommentMutation,useSetEliteReplyMutation,useGetLanguageByTopicMutation,useSetEliteShareMutation} = eliteScienceApi;