import React, { useEffect, useRef } from 'react';

const ThreeDPretty = () => {
  const spaceRef = useRef(null);
  const parts = 500;
  let _x = 0, _y = 0, _x1 = 0, _y1 = 0, _tx, _ty, _w, _h;
  const obj = [];

  const resize = () => {
    _w = window.innerWidth * 0.5;
    _h = window.innerHeight * 0.5;
  };

  const transform = (o) => {
    const ax = 0.02 * _tx,
          ay = 0.02 * _ty,
          cx = Math.cos(ax),
          sx = Math.sin(ax),
          cy = Math.cos(ay),
          sy = Math.sin(ay);

    const z = o.y * sx + o.z * cx;
    o.y = o.y * cx + o.z * -sx;
    o.z = o.x * -sy + z * cy;
    o.x = o.x * cy + z * sy;

    const sc = 1 / (1 + o.z),
          x = o.x * sc * _h + _w - sc * 2,
          y = o.y * sc * _h + _h - sc * 2;

    const p = o.p.style;
    if (x >= 0 && y >= 0 && x < _w * 2 && y < _h * 2) {
      const c = Math.round(256 + (-o.z * 256));
      p.left = `${Math.round(x)}px`;
      p.top = `${Math.round(y)}px`;
      p.width = `${Math.round(sc * 2)}px`;
      p.height = `${Math.round(sc * 2)}px`;
      p.background = `hsla(${y},80%,80%,1)`;
      p.zIndex = 200 + Math.floor(-o.z * 100);
    } else {
      p.width = "0px";
    }
  };

  const anime = () => {
    const se = 1 / _h;
    _tx = (_y - _x1) * se;
    _ty = (_x - _y1) * se;
    _x1 += _tx;
    _y1 += _ty;
    obj.forEach(o => transform(o));
    window.requestAnimationFrame(anime);
  };

  const setup = (n, f) => {
    for (let i = 0; i < n; i++) {
      const o = {};
      o.p = document.createElement('span');
      spaceRef.current.appendChild(o.p);
      let r = i / n, j, a, b;
      j = i % (n * 0.5);
      a = Math.floor(j) / 100 + (Math.floor(j / 2) % 10) / 5 * Math.PI * 2;
      b = Math.acos(-0.9 + (j % 4) * 0.6);
      r = f(r);
      const sbr = Math.sin(b) * r;
      o.x = Math.sin(a) * sbr;
      o.y = Math.cos(a) * sbr;
      o.z = Math.cos(b) * r;
      obj.push(o);
    }
    anime();
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      _x = e.clientX;
      _y = e.clientY;
    };
    
    const handleTouchMove = (e) => {
      e.preventDefault();
      _x = e.touches[0].clientX;
      _y = e.touches[0].clientY;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);
    resize();
    window.addEventListener('resize', resize);

    setup(parts, r => r * r);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('resize', resize);
    };
  }, []);

  return <div id="space" ref={spaceRef}></div>;
};

export default ThreeDPretty;
