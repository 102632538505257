import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Tokenomics() {
  const location = useLocation();
  const tokenRef = useRef(null);

  useEffect(() => {
    const scrollToRef = (ref) => {
      console.log(ref.current,"tokenRef")
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#Tokenomics":
        scrollToRef(tokenRef);
        break;
      default:
        break;
    }
  }, [location.hash]);
  return (
    <div className=" p60  bg_blar" id="Tokenomics" ref={tokenRef}>
      <div className="container">
        <h2 className=" mb-4 hadding text-center">Tokenomics</h2>
        <div className="token-img">
          <img src="/assets/img/coin.png"/>
        </div>
        <div className="row   align-items-center">
          <h6>Welcome to FSADUSD – Your Gateway to a Stable Digital Future</h6>
          <p>FSADUSD is a stablecoin that combines the benefits of cryptocurrencies with the security of traditional fiat currencies. It is backed by historical assets and offers a 1:1 redemption value against the US dollar. The currency is also integrating quantum technology to address fraud, delays, and transparency issues in the industry. The Committee FSAD369, a group of visionaries and investors, collaborates with banks and financial institutions to promote human rights, economic justice, and sustainable development. FSADUSD is not just a financial tool but a transformative movement aiming to reshape the global economic landscape based on transparency, inclusivity, and stability. It is designed for rapid global payments and accessible 24/7 financial markets</p>
          {/* <p>
            In a rapidly evolving financial landscape marked by the volatility
            of digital currencies, the FSADUSD Stablecoin offers a unique
            solution grounded in stability and reliability. Our stablecoin is
            meticulously backed by historical assets, ensuring a steadfast 1:1
            redemption value against the US dollar. This innovative currency
            merges the borderless possibilities of cryptocurrencies with the
            trust and stability of traditional fiat currencies, catering to the
            security that users expect and deserve.
          </p>
          <h6>
            Revolutionizing Financial Transactions with Quantum Technology
          </h6>
          <p>
            At the forefront of our initiative is the integration of quantum
            technology into financial systems—designed to tackle the pressing
            challenges of fraud, delays, and transparency that plague the
            current industry. By leveraging the inherent attributes of quantum
            computing, such as superposition and entanglement, we can process
            multiple accounts simultaneously, enhancing transaction speeds and
            safeguarding data integrity. This approach not only addresses the
            immediate challenges of contemporary finance but also establishes
            secure communication channels that are highly resistant to
            interception and fraud.
          </p>
          <h6>Empowering Change Through Collective Innovation</h6>
          <p>
            Central to this transformative journey is the Committee FSAD369.
            Comprising a diverse group of visionaries and investors with a
            commitment to utilizing historical assets, this committee
            collaborates closely with banks and financial institutions. Our
            mission extends beyond creating a stablecoin; we strive to promote
            human rights, economic justice, and sustainable development. By
            fostering equitable opportunities and implementing fair economic
            policies, our goal is to architect a brighter future for communities
            around the globe through strategic growth initiatives and legal
            frameworks.
          </p>
          <h6>Join Us in Shaping Global Economic Stability</h6>
          <p>
            The FSADUSD Stablecoin is not just a financial tool; it’s a
            transformative movement aimed at reshaping the global economic
            landscape based on principles of transparency, inclusivity, and
            stability. With FSADUSD, you can engage in seamless value exchanges,
            empowering economic prosperity to reach even the most remote corners
            of the world.
          </p>
          <p>
            Fully backed by historical assets, the FSADUSD Coin is designed for
            rapid global payments and accessible 24/7 financial markets. As a
            regulated digital currency that guarantees a 1:1 redemption for US
            dollars, we offer a reliable, secure option in your financial
            journey.
          </p>
          <p>
            Join us today and be part of a forward-thinking community dedicated
            to innovation, trust, and real change in the world of finance.
            Together, let’s pave the way for a more equitable and sustainable
            economic future with FSADUSD.
          </p> */}

        </div>
        <div className="text-center mt-3">
          <a className="btn btn-primary" href="https://fsadusd.com/" target="_blank">Read More</a>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
