import { getGalleryApi } from "../components/constant/api";
import { myApi } from "./api";

export const galleryApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getGallery: builder.query({
      query: () => ({
        url: getGalleryApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetGalleryQuery} = galleryApi;