import React,{useEffect} from "react";
import { useGetMemberQuery } from "../../../redux/memberApi";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import ThreeDPretty from "../../widgets/ThreeDpretty";

function Members() {
    const {data}=useGetMemberQuery()
    useEffect(()=>{
      scrollTop()
  },[])
  const scrollTop=()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
      }
  return (
    <>
      <ThreeDPretty />
      <Header />
      <section className="in_page position-relative">
        <div className="container">
          <div className="section-title section-title-two mb-4 text-center">
            <h2 className="title hadding  ">Leadership & Members</h2>
           
          </div>

          <div className="game ">
            
              <div className="row">
                {data?.map((list) => {
                  return (
                    <div className="col-md-3 col-sm-6 mb-4 " key={list.id}>
                      <div className="choose-item">
                        <img src={list.image} className="team_img" />
                        <h4>{list.name}</h4>
                        <p>{list.position}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
          
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Members;
