import React from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function GalleryDescriptionModal(props) {

    const handleClose=()=>{
      props?.setShowModal(false)
    }

    const handleDetail=()=>{
        window.location.href="https://fsadusd.com/assets-library"
    }

  return (
    <>
     <Modal show={props.showModal} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          {" "}
          <Modal.Title >Description</Modal.Title>
        </Modal.Header>

        <Modal.Body>
         <div className='row'>
          <div className='col-md-6'>
            <img src={props.currentRecord.image} alt='' className='img-fluid'/>
          </div>
          <div className='col-md-6'>
            <h4>{props.currentRecord.title}</h4>
            <p>{props.currentRecord.short_description}</p>
          </div>
         </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleDetail}>
            More Details
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
   
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GalleryDescriptionModal