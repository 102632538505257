import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"

function Financial() {
  const location = useLocation();
  const qfsRef = useRef(null);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#QFS":
        scrollToRef(qfsRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <section className="about-area-two p60 " id="QFS" ref={qfsRef}>
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div className="about-img-two text-center">
              <img
                src="/assets/img/about2.png"
                alt="token"
                className="img-fluid "
              />
            </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="about-content-two">
              <div className="section-title section-title-two mb-15">
                <h2 className="title hadding mb-4">
                  Quantum Financial System <span>(QFS)</span>{" "}
                </h2>
              </div>
              <div className="about_sc">
                <p>
                  The Quantum Financial System (QFS) is a groundbreaking
                  evolution in global finance, revolutionizing traditional
                  monetary frameworks through advanced technologies like
                  blockchain and quantum computing. This modern system enhances
                  transaction speed, data security, and transparency on a global
                  scale, promising efficiency unparalleled by conventional
                  methods.
                </p>
                <p>
                  At its core, QFS utilizes quantum computing's immense
                  computational power to process complex transactions swiftly
                  and securely, marking a significant departure from traditional
                  banking norms. This transformative approach not only ensures
                  rapid transaction speeds but also fosters a decentralized,
                  immutable transaction ecosystem that is both scalable and
                  secure.
                </p>
                <p>
                  In collaboration with Nesara/Gesara, Quantum Ledger Banking
                  (QDB) emerges as a pivotal component of this new financial
                  landscape. Designed to operate independently of governmental
                  policies, QDB secures digital assets such as Gold, Platinum,
                  and Oil, ensuring robust financial stability backed by
                  tangible resources rather than fiat currencies.
                </p>
                <p>
                  Central Bank Digital Currencies (CBDCs) are integral to this
                  paradigm shift, offering digital alternatives to physical cash
                  for faster and more secure transactions. Moreover, the
                  integration of quantum technology addresses longstanding
                  industry challenges such as fraud and lack of transparency,
                  leveraging principles like superposition and entanglement to
                  enhance data integrity and communication security.
                </p>
                <p>
                  ISO 20022 standards play a crucial role in this
                  transformation, standardizing financial messaging and data
                  exchange globally among financial institutions. This adoption
                  enhances interoperability and efficiency across legacy
                  systems, reflecting a forward-looking approach to financial
                  operations.
                </p>
                <p>
                  In tandem with these advancements, the shift towards
                  sustainable development underscores a commitment to mitigating
                  environmental impact and promoting a greener economy. This
                  holistic approach ensures not only financial resilience but
                  also environmental responsibility, paving the way for a more
                  efficient and sustainable future.
                </p>
                <p>
                  By embracing the Quantum Financial System, stakeholders embark
                  on a transformative journey towards a more secure,
                  transparent, and sustainable global financial landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Financial;
