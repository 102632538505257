import React, { useState, useEffect } from "react";
import Footer from "../widgets/Footer";
import Header from "../widgets/Header";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { LoginValid } from "../validations/LoginValid";
import { useSetStaffLoginMutation } from "../../redux/user";

function Login() {
  const { authenticated, login,setUserLike,setUserDisLike  } = useAuth();
  const [setStaffLogin] = useSetStaffLoginMutation();
  const navigate = useNavigate();
  const [loginField, setLoginField] = useState({ userName: "", password: "" });
  const [loginFieldErr, setLoginFieldErr] = useState({
    userName: "",
    password: "",
  });

  useEffect(() => {
    if (!authenticated) {
      navigate("/login", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  }, [authenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginField({ ...loginField, [name]: value });
    let checkLogin = LoginValid(name, value);
    setLoginFieldErr({ ...loginFieldErr, [name]: checkLogin });
  };

  const onLogin = async (event) => {
    event.preventDefault();
    for (let key in loginField) {
      let checkLogin = LoginValid(key, loginField[key]);
      setLoginFieldErr({ ...loginFieldErr, [key]: checkLogin });
      if (checkLogin !== "") {
        return false;
      }
    }
    let LoginData = {
      email: loginField.userName,
      password: loginField.password,
    };
    setStaffLogin(LoginData).then((result) => {
      if (result.data.status) {
        let token = result.data.authtoken;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", result.data.type);
        localStorage.setItem("user_like", JSON.stringify(result.data.user_like));
        localStorage.setItem("user_dislike", JSON.stringify(result.data.user_dislike));
        setUserLike(result.data.user_like)
        setUserDisLike(result.data.user_dislike)
        toast.success(result.data.message);
        login();
        return false;
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        return;
      }
    });
  };
  return (
    <>
      <Header />
      <section className="in_page">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 m-auto">
              <img
                src="/assets/img/login.png"
                alt="login"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="big_box  bg_blar ">
                <h5 className="mb-3 text-center">Welcome Back</h5>
                <form onSubmit={onLogin}>
                  <div class="form-group">
                    <label class="">
                      Email <span className="text-white">*</span>
                    </label>
                    <input
                      type="text"
                      name="userName"
                      className="form-control"
                      id="yourUsername"
                      value={loginField.userName}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label class="">
                      Password <span className="text-white">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="yourPassword"
                      value={loginField.password}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <button className="btn w100" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
                {/* <div class="text-center mt-4">
                  Haven't sign up yet?{" "}
                  <a className="color1" href="register">
                    Sign Up
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Login;
