import React,{useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import { useSetUserLoginMutation } from "../../redux/user";
import { Emailpattern, Namepattern } from "../patterns/Pattern";

function UserLoginModal(props) {
    const {login,setUserLike,setUserDisLike  } = useAuth();
    const [setUserLogin]=useSetUserLoginMutation()
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [disable, setDisable] = useState(false)

  const handleClose = () => {
    props.setShow(false);
    setEmail("")
    setEmailErr("")
    setName("")
    setNameErr("")
    setDisable(false)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("This field is required");
      } else if (!Namepattern.test(value)) {
        setNameErr("Please enter valid name");
      } else {
        setNameErr("");
      }
    }
    if (name === "email") {
      setEmail(value);
      if (value === "") {
        setEmailErr("This field is required");
      } else if (!Emailpattern.test(value)) {
        setEmailErr("Please enter valid email");
      } else {
        setEmailErr("");
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailErr("This field is required");
      return false;
    }
    if (!Emailpattern.test(email)) {
      setEmailErr("Please valid email");
      return false;
    }
    if (name == "") {
      setNameErr("This field is required");
      return false;
    }
    if (!Namepattern.test(name)) {
      setNameErr("Please valid name");
      return false;
    }
    setDisable(true)
    const LoginData = {
      email,
      name,
    };
    setUserLogin(LoginData).then((result) => {
        if (result.data.status) {
          let token = result.data.authtoken;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("userType", result.data.type);
          localStorage.setItem("user_like", JSON.stringify(result.data.user_like));
          localStorage.setItem("user_dislike", JSON.stringify(result.data.user_dislike));
          setUserLike(result.data.user_like)
          setUserDisLike(result.data.user_dislike)
          handleClose()
          toast.success(result.data.message);
     
          login();
          return false;
        } else {
            setDisable(false)
          toast.dismiss();
          toast.error(result.data.message);
          return;
        }
      });
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose} className="user-login-modal">
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>Visitor Login</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="mb-2">
              <label className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Enter Email"
                value={email}
                onChange={handleChange}
                name="email"
              />
              <span className="text-danger">{emailErr}</span>
            </div>
            <div className="mb-2">
              <label className="form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Enter Name"
                value={name}
                onChange={handleChange}
                name="name"
              />
              <span className="text-danger">{nameErr}</span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserLoginModal;
