import { getMembersApi } from "../components/constant/api";
import { myApi } from "./api";

export const memberApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMember: builder.query({
      query: () => ({
        url: getMembersApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),

    
  }),
});

export const {useGetMemberQuery} = memberApi;