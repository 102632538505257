export const languageEnum=[
    {
        key:"English",
        value:"English"
    },
    {
        key:"Arabic",
        value:"Arabic"
    },
    {
        key:"German",
        value:"German"
    },
    {
        key:"Bengali",
        value:"Bengali"
    },
    {
        key:"Russian",
        value:"Russian"
    },

]

export const likeDisLike={
    Like:"Like",
    DisLike:"Dislike"
}


export const userTypeEnum={
    User:"3",
    Admin:"1",
    Staff:"2",
}