import Home from "./components/pages/home/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import EliteScience from "./components/pages/eliteScience/EliteScience";
import ElitescienceDetail from "./components/pages/eliteScience/ElitescienceDetail";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Members from "./components/pages/members/Members";
import Gallery from "./components/pages/gallery/Gallery";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Register />}/>
          <Route path="/#QFS" element={<Home />} />
          <Route path="/#about" element={<Home />} />
          <Route path="/#Tokenomics" element={<Home />} />
          <Route path="/#EliteScience" element={<Home />} />
          <Route path="/#Contact" element={<Home />} />
          <Route path="/elite-sciences" element={<EliteScience />} />
          <Route path="/elite-sciences/:sciencebox_id/:topic_id/:language_id" element={<EliteScience />} />
          <Route path="/members" element={<Members />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/elite-sciences-detail/:id" element={<ElitescienceDetail />} /> */}
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
