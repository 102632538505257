import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function About() {
  const location = useLocation();
  const aboutUsRef = useRef(null);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#about":
        scrollToRef(aboutUsRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <section
      id="about"
      className="about-area-two p60 bg_blar"
     ref={aboutUsRef}
    >
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img-two text-center">
              <img
                src="/assets/img/about.png"
                alt="token"
                className="img-fluid "
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-two">
              <div className="section-title section-title-two mb-15">
                <h2 className="title hadding mb-4">
                  ABOUT <span>US</span>{" "}
                </h2>
              </div>
              <div className="about_sc">
                <p>
                  In an era marked by rapid digital revolution and the emergence
                  of the quantitative financial system, which modifies the
                  financial infrastructure, and utilizes technologies such as
                  blockchain and quantum computing to organize transactions
                  instantly and securely, thanks to the unprecedented
                  computational speed of quantum computing. These developments
                  will play a significant role in enhancing the digital banking
                  landscape by linking transactions to tangible assets such as
                  gold and oil, ensuring a strong foundation for financial
                  operations.
                </p>
                <p>
                  At the heart of this radical transformation lies Committee
                  FSAD369, established through the initiative of a group of
                  individuals and investors who possess historical assets and
                  engage with banks and financial institutions. The committee
                  consists of members committed to forward-thinking, aiming to
                  leverage underutilized asset potentials to support human
                  rights, promote economic justice, foster sustainable
                  development, and empower communities. The committee works on
                  global collaboration to effect meaningful change by enhancing
                  equal opportunities, fair economic policies, growth-oriented
                  strategies, legal initiatives, and cooperative efforts.
                </p>
                <p>
                  Committee FSAD369 stands at the forefront of this bold
                  endeavor to reshape the global economic landscape,
                  facilitating seamless value exchange processes through the
                  replacement and restoration of the value of these assets,
                  thereby promoting widespread economic prosperity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
