import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";
import {
  useGetEliteScienceByIdMutation,
  useGetEliteUserCommentMutation,
  useSetEliteCommentMutation,
  useSetEliteLikeDislikeMutation,
} from "../../../redux/eliteScienceApi";
import UserLoginModal from "../../auth/UserLoginModal";
import { likeDisLike, userTypeEnum } from "../../constant/enum";
import { jwtDecode } from "jwt-decode";
import EliteReplyModa from "../../partial/EliteReplyModa";
import EliteDescriptionModal from "../../partial/EliteDescriptionModal";
import ShareModal from "../../partial/ShareModal";

function ElitescienceDetail({id,handleSearch,language_id}) {
  // const { id } = useParams();
  const userType=localStorage.getItem("userType")
  const token=localStorage.getItem("jwtToken")
  const [setEliteComment]=useSetEliteCommentMutation()
  const [getEliteUserComment,{data:commentsData}]=useGetEliteUserCommentMutation()
  const { userLike, setUserLike, userDisLike, setUserDisLike, authenticated } =
    useAuth();
  const [setEliteLikeDislike] = useSetEliteLikeDislikeMutation();
  const [getEliteScienceById, { data }] = useGetEliteScienceByIdMutation();
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("")
  const [commentAddStatus, setCommentAddStatus] = useState(true)
  const [userId, setUserId] = useState("")
  const [commentId, setCommentId] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [description, setDescription] = useState("")
  const [showDescription, setShowDescription] = useState(false)
  const [shareShowModal, setShareShowModal] = useState(false)
  
  useEffect(() => {
    if(token && commentsData &&  commentsData.length>0){
      const decode = jwtDecode(token);
      setUserId(decode.id)
      const filter =commentsData.filter((list)=>{return list.user_id==decode.id})
      if(filter.length>0){
        setCommentAddStatus(false)
      }
    }
  }, [token,commentsData])
  
  useEffect(() => {
    if (id) {
      getEliteScienceById({ id });
      getEliteUserComment({id})
    }
  }, [id]);

  const handleLikeDislike = async (value) => {
    if (authenticated) {
      const sendData = {
        type: value,
        id: data?.id,
      };
      setEliteLikeDislike(sendData).then((result) => {
        if (result.data.status) {
          let resp = result.data;
          localStorage.setItem("user_like", JSON.stringify(resp.user_like));
          localStorage.setItem(
            "user_dislike",
            JSON.stringify(resp.user_dislike)
          );
          toast.success(result.data.message);
          setUserLike(resp.user_like);
          setUserDisLike(resp.user_dislike);
          getEliteScienceById({ id });
          return false;
        } else {
          toast.dismiss();
          toast.error(result.data.message);
          return;
        }
      });
    } else {
      setShow(true);
    }
  };

  const hangeCommit=(e)=>{
    setComment(e.target.value)
  }

  const handleSubmitCommet=async(e)=>{
    e.preventDefault()
    if(authenticated){
    const data={
      elite_science_id:id,
      comment,
    }
    setEliteComment(data).then((result) => {
      if (result.data.status) {
        toast.success(result.data.message);
        setComment("")
        getEliteUserComment({id})
        return false;
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        return;
      }
    });
  }
    else {
      setShow(true);
    }
  }

  const handleOpenReplyModal=(id)=>{
    setShowModal(true)
    setCommentId(id)
  }

  const handleshowDescription=(description)=>{
    setDescription(description)
    setShowDescription(true)
  }
 
  const handleShareModal=()=>{
    if(authenticated){
      setShareShowModal(true);
    }
    else{
        setShow(true)
    }
    
  }

  return (
    <>
    
      <section className=" position-relative">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="elite_box p-2  p-md-4 bg_blar mb-2">
              {data?.image && <a href={data?.youtube_link} target="_blank">  <img src={data?.image} className="youtub_img mb-0" alt="" /></a>}
                <div className="mt-4">
                <div className="">
                <h3 className={language_id=="Arabic"?"text-end":""}>{data?.title}</h3>
                <p className={language_id=="Arabic"?"text-end":""}>{data?.short_description} <br/><button className="btn mt-3" onClick={()=>{
                  handleshowDescription(data?.description)
                }}>View More</button></p>
                </div>
                <div className="text-end">
                  <div className="mb-2">
                    <a href={data?.wallet_link}>{data?.wallet_id}</a>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleLikeDislike(likeDisLike.Like);
                    }}
                    disabled={userLike?.includes(data?.id)}
                  >
                    <i class="fa fa-thumbs-up"></i> {data?.total_like}
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      handleLikeDislike(likeDisLike.DisLike);
                    }}
                    disabled={userDisLike?.includes(data?.id)}
                  >
                    <i class="fa fa-thumbs-down"></i> {data?.total_dislike}
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => {
                     handleShareModal()
                    }}
                    
                  >
                    <i class="fa fa-share"></i> {data?.total_share}
                  </button>
                </div>
              </div>
              </div>
            </div>
            
            <div className="col-12">
            

              <div className="col-md-12 bootstrap snippets">
                <div className="d-flex mb-4 mt-4     align-items-center">
                  <h4 className="pe-4 mb-0">{commentsData?.length} Comment</h4>
                  <div className="pe-3">
                    {" "}
                    <i className="fa fa-align-left" aria-hidden="true"></i>{" "}
                  </div>
                  <div className=""> Sort By </div>
                </div>
                { commentAddStatus?<div className="d-flex mb-4">
                  <img
                    className="img-circle img-sm "
                    alt="Profile Picture"
                    src="https://bootdey.com/img/Content/avatar/avatar1.png"
                  />
                  <div className="ps-4 w100 ">
                    <>
                    <form onSubmit={handleSubmitCommet} className="d-md-flex">
                      <textarea
                      className="comment_t"
                      placeholder="Add a Comment"
                      value={comment}
                      onChange={hangeCommit}
                    />
                    <button className="btn btn-primary ms-md-4" type="submit">Submit</button>
                    </form>
                    </>
                    
                  </div>
                </div>:""}
                <div className="panel">
                  <div className="panel-body">
                    {/* Newsfeed Content */}
                    {/*===================================================*/}
                    {commentsData?.map((list)=>{
                      return (
                        <>
                         <div className="media-block">
                      <a className="media-left" href="#">
                        <img
                          className="img-circle img-sm"
                          alt="Profile Picture"
                          src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        />
                      </a>
                      <div className="media-body">
                        <div className="mar-btm d-flex align-items-center mb-2">
                          <a href="#" className="h6 mb-0">
                            {list.name}
                          </a>
                          <p className="mb-0 ms-2">{list.createdAt}</p>
                        </div>
                        <div className="d-flex">
                          <p className=" me-auto">
                            {list.user_comment}
                            {/* <a href="#" className="ms-2">
                              View More
                            </a> */}
                          </p>

                          {/* <div class="dropdown">
                            <a
                              href="#"
                              className="h4"
                              data-bs-toggle="dropdown"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item" href="#">
                                  Link 1
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Link 2
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Link 3
                                </a>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                        {list.reply_name?<div className="Coment_to_coment mb-3">
                          <b>{list.reply_name}</b>
                          <br />
                          <small>
                            {" "}
                            {list.reply_comment}
                          </small>{" "}
                          {/* <a href="#" className="ms-2">
                            View More
                          </a> */}
                        </div>:userType==userTypeEnum.Staff && userId!=list.user_id?
                        <div className="pad-ver">
                          {/* <div className="btn-group">
                            <a className="d-inline-block " href="#">
                              <i className="fa fa-thumbs-up" />
                            </a>
                            <a className="d-inline-block ms-3" href="#">
                              <i className="fa fa-thumbs-down" />
                            </a>
                          </div> */}
                          <button className="d-inline-block ms-4" onClick={()=>{handleOpenReplyModal(list.id)}}>
                            Reply
                          </button>
                        </div>:""}
                        
                        
                      </div>
                    </div>
                    <hr />
                        </>
                      )
                    })}
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EliteReplyModa elite_science_id={id} getEliteUserComment={getEliteUserComment} id={commentId} setShowModal={setShowModal} showModal={showModal}/>
      <UserLoginModal show={show} setShow={setShow} />
      <EliteDescriptionModal description={description} showDescription={showDescription} setShowDescription={setShowDescription} language_id={language_id}/>
      <ShareModal setShareShowModal={setShareShowModal} shareShowModal={shareShowModal} id={id} handleSearch={handleSearch}/>
    </>
  );
}

export default ElitescienceDetail;
