export const LoginValid=(name,value)=>{
    let error=""
    if(name==="userName"){

       if(value===""){
       error="This field is required"
          return error
       }
    
       return error
    }
    if(name==="password"){

      if(value===""){
         error="This field is required"
            return error
         }
        
         return error
    }
}