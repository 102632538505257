import React from 'react'
import About from '../../widgets/About'
import Banner from '../../widgets/Banner'
import ContactUs from '../../widgets/ContactUs'
import EliteScience from '../../widgets/EliteScience'
import Financial from '../../widgets/Financial'
import Footer from '../../widgets/Footer'
import Gallery from '../../widgets/Gallery'
import Header from '../../widgets/Header'
import Members from '../../widgets/Members'
import ThreeDPretty from '../../widgets/ThreeDpretty'
import Tokenomics from '../../widgets/Tokenomics'

function Home() {

  return (
    <>
    <ThreeDPretty/>
    <Header/>
    <Banner/>
    <About/>
    <Financial/>
    <Members/>
    <EliteScience/>
    <Tokenomics />
    <Gallery/>
    <ContactUs/>
    <Footer/>
    </>
  )
}

export default Home