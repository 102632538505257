import React,{useEffect,useState} from "react";
import { useGetGalleryQuery } from "../../../redux/galleryApi";
import GalleryDescriptionModal from "../../partial/GalleryDescriptionModal";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import ThreeDPretty from "../../widgets/ThreeDpretty";

function Gallery() {
  const { data } = useGetGalleryQuery();
  const [showModal, setShowModal] = useState(false)
  const [currentRecord, setCurrentRecord] = useState({})

  const handleModal=(record)=>{
    setCurrentRecord(record)
    setShowModal(true)
  }
  useEffect(()=>{
    scrollTop()
},[])
const scrollTop=()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
    }
  return (
    <>
      <ThreeDPretty />
      <Header />
      <section className="in_page position-relative">
        <div className="container">
          <div className="section-title section-title-two mb-4 text-center">
            <h2 className="title hadding  ">Asset Gallery</h2>
          </div>

          <div className="game ">
            <div className="row">
              {data?.map((list) => {
                return (
                  <div className="col-lg-3  mb-md-4" key={list.id}>
                    <div className="text-center">
                      <img
                        className="img-fluid br10"
                        src={list.image}
                        alt="cm"
                      />
                       <h6 className="mt-1">{list.title.length>30?`${list.title?.slice(0,30)}...`:list.title}</h6>
                <button className="btn btn-primary" onClick={()=>{handleModal(list)}}>Quick View</button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <GalleryDescriptionModal setShowModal={setShowModal} showModal={showModal} currentRecord={currentRecord}/>
      </section>
      <Footer />
    </>
  );
}

export default Gallery;
