import React from 'react'
import Footer from '../widgets/Footer'
import Header from '../widgets/Header'

function Register() {
  return (
    <>
         <Header/>
         <section className='in_page'>
           <div className='container'>
          
              <div className='row align-items-center'>
              <div className='col-md-5 m-auto'>
              <img src="/assets/img/login.png" alt="login" className="img-fluid" />
              </div>
              <div className='col-md-6'>
              <div className='big_box  bg_blar '>
              <h5 className='mb-3 text-center'>Welcome Back</h5>
               <div class="form-group">
                <label class="">Name</label>
                <input type='text' class="form-control" placeholder='Name' />
               </div>
               <div class="form-group">
                <label class="">Email</label>
                <input type='text' class="form-control" placeholder='Email' />
               </div>
               <div class="form-group">
                <label class="">Password</label>
                <input type='number' class="form-control" placeholder='Password' />
               </div>
               <div class="form-group">
                <label class=""> Confirm Password</label>
                <input type='number' class="form-control" placeholder=' Confirm Password' />
               </div>

              
               
               <div class="form-group">
                <button className='btn w100' type='submit'>Submit</button>
               </div>
               <div class="text-center mt-4">
               Already have an account? <a className='color1' href='login'>Sign In</a>
               </div>
              </div>
              </div>
              </div>
            
           </div>
        </section>
        <Footer/>
    </>
  )
}

export default Register