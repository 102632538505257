import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetFilterEliteScienceMutation,
  useGetLanguageByTopicMutation,
  useGetScienceBoxQuery,
  useGetTopicByscienceBoxMutation,
} from "../../../redux/eliteScienceApi";
import { languageEnum } from "../../constant/enum";
import Loader from "../../constant/Loader";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import ThreeDPretty from "../../widgets/ThreeDpretty";
import ElitescienceDetail from "./ElitescienceDetail";

function EliteScience() {
  const navigate=useNavigate()
  const {sciencebox_id,topic_id,language_id}=useParams()
  const { data: scienceboxList } = useGetScienceBoxQuery();
  const [getLanguageByTopic,{data:languageData}]=useGetLanguageByTopicMutation()
  const [getTopicByscienceBox, { data: topicData }] =
    useGetTopicByscienceBoxMutation();
  const [getFilterEliteScience, { data, isLoading }] =
    useGetFilterEliteScienceMutation();
  const [topicList, setTopicList] = useState([]);
  const [scienceBoxId, setScienceBoxId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [language, setLanguage] = useState("");
  const [eliteStatus, setEliteStatus] = useState(false)
  useEffect(()=>{
    scrollTop()
},[])
const scrollTop=()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
    }
    
  // useEffect(() => {
  //   if (scienceBoxId && topicId && language) {
  //     const data = {
  //       science_box_id: scienceBoxId,
  //       topic_id: topicId,
  //       language,
  //     };
  //     getFilterEliteScience(data);
  //   }
  // }, []);

  useEffect(() => {
    if (scienceBoxId != "") {
      getTopicByscienceBox({ id: scienceBoxId });
    }
  }, [scienceBoxId]);

  useEffect(() => {
    if (topicId != "") {
      getLanguageByTopic({ id: topicId });
    }
  }, [topicId]);
  
  useEffect(() => {
   if(sciencebox_id && topic_id && language_id){
    setScienceBoxId(sciencebox_id)
    setTopicId(topic_id)
    setLanguage(language_id)
    const data = {
      science_box_id: sciencebox_id,
      topic_id: topic_id,
      language:language_id,
    };
    getFilterEliteScience(data);
 
   }
  }, [sciencebox_id,topic_id,language_id])
  


  useEffect(() => {
    if (topicData && topicData.length > 0) {
      setTopicList(topicData);
    }
  }, [topicData]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "scienceBoxId") {
      setScienceBoxId(value);
      setTopicList([]);
      setTopicId("");
    }
    if (name === "topicId") {
      setTopicId(value);
    }
    if (name === "language") {
      setLanguage(value);
    }
  };

  const handleSearch = () => {

    if(!language || !topicId || !scienceBoxId){
      toast.error("please select all field")
      return
    }
    setEliteStatus(true)
    const data = {
      science_box_id: scienceBoxId,
      topic_id: topicId,
      language,
    };
    getFilterEliteScience(data);
    navigate(`/elite-sciences/${scienceBoxId}/${topicId}/${language}`)
  };

  return (
    <>
      <ThreeDPretty />
      <Header />
      <section className="in_page position-relative">
        <div className="container">
          <div className="section-title section-title-two mb-4 text-center">
            <h2 className="title hadding  ">
              Elite <span>Sciences</span>{" "}
            </h2>
          </div>
          <p>
            The sciences of the elite, or sciences of the great, constitute a
            body of knowledge studied and developed to achieve personal and
            spiritual advancement for individuals. These sciences focus on a
            deeper understanding of oneself and the world around us,
            encompassing the study of philosophy, ethics, psychology, history,
            and more.
          </p>
          <p>
            They help foster tolerant and balanced perspectives. These sciences
            are considered tools for achieving effective leadership and positive
            impact in society, enabling educated individuals to use their
            knowledge to improve both individual and collective life in a
            sustainable and balanced manner. We will explore and discuss some of
            these sciences on our platform to clarify them, you can find and
            select the topics of your interest from below dropdowns menus, and
            we hope everyone benefits from this knowledge.
          </p>
          <div className="big_box  bg_blar ">
            <div className="row">
              <div className="col-md-3 mb-3 mb-md-0">
                <label className="form-label">Science Box</label>
                <select
                  className="form-control position-relative"
                  value={scienceBoxId}
                  onChange={handleChange}
                  name="scienceBoxId"
                >
                  <option value="">Search</option>
                  {scienceboxList?.map((list) => {
                    return (
                      <option value={list.id} key={list.title}>
                        {list.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label className="form-label">Topic</label>
                <select
                  className="form-control position-relative"
                  value={topicId}
                  onChange={handleChange}
                  name="topicId"
                >
                  <option value="">Search</option>
                  {topicList?.map((list) => {
                    return (
                      <option value={list.id} key={list.title}>
                        {list.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label className="form-label">Language</label>
                <select
                  className="form-control position-relative"
                  value={language}
                  onChange={handleChange}
                  name="language"
                >
                  <option value="">Search</option>
                  {languageData?.map((list) => {
                    return (
                      <option value={list.language} key={list.language}>
                        {list.language}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 elite-search">
                <button className="btn btn-primary" onClick={handleSearch}>
                  Lets Go
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="row   align-items-center mt-md-5 mt-4">
              {data?.length > 0 ? (
                // data?.map((list) => {
                //   return (
                   <ElitescienceDetail id={data[0]?.id} handleSearch={handleSearch} language_id={language_id}/>
                //   );
                // })
              ) : !eliteStatus?(
                <div>
                  <h4 className="text-center mb-4">About Sciences Fund</h4>
                  <h6>1. False Knowledge Fund</h6>
                  <div className="ms-3">
                    <p>
                      Pseudoscience refers to a set of ideas and beliefs that
                      claim to be scientific but lack rigorous scientific
                      methodology and reliable empirical evidence. While genuine
                      science relies on experimentation, verification, and peer
                      review, pseudoscience disregards these fundamental
                      principles, making it non-falsifiable and often promoting
                      results that are not supported by strong evidence.
                    </p>
                    <p>
                      Pseudoscience is characterized by reliance on personal
                      testimonies, promotion of concepts without rigorous
                      scientific testing, and often aims for commercial or
                      personal gains rather than genuine research. By focusing
                      on unproven theories and marketing them in misleading
                      ways, pseudoscience can mislead the public and negatively
                      impact scientific understanding.
                    </p>
                    <p>
                      Therefore, it is crucial to be aware of basic scientific
                      standards and verify the accuracy of the information we
                      receive, to maintain a clear and reliable understanding of
                      science.
                    </p>
                  </div>
                  <h6>2. Women's & Family Affaires Fund</h6>
                  <div className="ms-3">
                    <p>
                      The Women and Family Care Path focuses on enhancing the
                      role of women and supporting families through a range of
                      initiatives and programs. This path aims to empower women
                      in various aspects of life, including education,
                      employment, and leadership, by providing the necessary
                      support to ensure they have equal opportunities and
                      rights.
                    </p>
                    <p>
                      The path also emphasizes family care by offering social
                      and psychological services that support work-life balance.
                      This includes developing programs that provide support for
                      parents, promoting flexible work environments, and
                      offering counseling and guidance to ensure family
                      well-being.
                    </p>
                    <p>
                      By collaborating with government agencies,
                      non-governmental organizations, and local communities, the
                      Women and Family Care Path seeks to build a supportive
                      society that contributes to sustainable development and
                      social justice.
                    </p>
                  </div>
                  <h6>3. Human Wellbeing Fund</h6>
                  <div className="ms-3 elite-ul">
                    <p>
                      The Human Wellbeing Pathway is a holistic approach aimed
                      at enhancing individuals' quality of life by integrating
                      cutting-edge technological innovations with sustainable
                      environmental strategies. This pathway encompasses four
                      key areas that contribute to achieving comprehensive
                      health and wellbeing:
                    </p>
                    <ul className="ms-3">
                      <li>
                        <p>
                          <b>Health Technology:</b> We focus on leveraging the
                          latest technologies to develop tools and applications
                          that enhance personal care and support diagnosis and
                          treatment, such as artificial intelligence and
                          wearable devices.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Carbon Footprint Reduction: </b>We work on reducing
                          carbon emissions through the transition to renewable
                          energy sources, promoting sustainable transportation,
                          and improving energy efficiency.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Climate Change:</b>We address the challenges posed
                          by climate change by promoting sustainability in
                          agriculture and resource management, and developing
                          climate-resilient infrastructure.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Environmental Challenges: </b>We aim to protect
                          public health by reducing pollution, managing natural
                          resources effectively, and increasing environmental
                          awareness.
                        </p>
                      </li>
                    </ul>
                    <p>
                      Through this pathway, we are committed to providing
                      innovative solutions that improve personal health and
                      protect the environment, achieving a holistic and
                      sustainable wellbeing for individuals and communities.
                    </p>
                  </div>
                  <h6>4. Technology & Science Fund</h6>
                  <div className="ms-3">
                    <p>
                      The Technology & Science Fund is a dedicated initiative
                      aimed at fostering innovation and supporting research
                      across various fields of science and technology. By
                      providing financial resources, mentorship, and
                      collaborative opportunities, the fund aims to accelerate
                      the development of groundbreaking ideas and solutions that
                      address contemporary global challenges.
                    </p>
                    <p>
                      Focused on areas such as renewable energy, biotechnology,
                      artificial intelligence, and advanced materials, the
                      Technology & Science Fund seeks to promote projects that
                      have the potential for significant societal impact. It
                      supports both emerging startups and established
                      organizations, ensuring a diverse range of innovations
                      that can contribute to sustainable development and
                      economic growth.
                    </p>
                    <p>
                      In addition to financial backing, the fund also emphasizes
                      the importance of knowledge exchange and collaboration
                      among scientists, researchers, and industry leaders. By
                      fostering partnerships and encouraging interdisciplinary
                      approaches, the Technology & Science Fund aims to drive
                      advancements that enhance quality of life, promote
                      environmental stewardship, and create new opportunities
                      for future generations.
                    </p>
                    <p>
                      In summary, the Technology & Science Fund is committed to
                      investing in the future by empowering innovators and
                      researchers to transform their ideas into tangible
                      solutions that benefit society and the planet as a whole.
                    </p>
                  </div>
                  <h6>5. Sovereign Debt Fund:</h6>
                  <div className="ms-3">
                    <p>
                      A Sovereign Debt Fund is an investment vehicle focused on
                      purchasing and managing debt issued by national
                      governments. These funds typically invest in government
                      bonds or other sovereign debt instruments, aiming to
                      generate returns for their investors while providing
                      liquidity and stability to the government debt market.
                    </p>
                    <p>
                      Sovereign Debt Funds may be used by a variety of
                      institutional investors, including pension funds,
                      insurance companies, and sovereign wealth funds. They are
                      often considered lower risk compared to corporate debt
                      funds, given that government bonds are generally seen as
                      more secure, especially those issued by stable and
                      creditworthy nations.
                    </p>
                    <p>
                      The fund's performance is influenced by factors such as
                      interest rates, inflation, and the creditworthiness of the
                      issuing governments. Sovereign Debt Funds can be actively
                      or passively managed, with active funds seeking to
                      outperform benchmarks through strategic bond selection and
                      timing decisions, while passive funds aim to replicate the
                      performance of a specific sovereign debt index.
                    </p>
                  </div>
                </div>
              ):<p className="text-center">No record found</p>}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default EliteScience;
