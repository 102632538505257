
import { createContext, useContext, useState,useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("jwtToken")?true:false);
  const [userLike, setUserLike] = useState(localStorage.getItem("user_like")?JSON.parse(localStorage.getItem("user_like")):[])
  const [userDisLike, setUserDisLike] = useState(localStorage.getItem("user_dislike")?JSON.parse(localStorage.getItem("user_dislike")):[])

 useEffect(() => {
  if(localStorage.getItem("jwtToken")){
    setAuthenticated(true);
  }
 }, [authenticated])

  const login = () => {
    // Implement your authentication logic here
    setAuthenticated(true);
  };

  const logout = () => {
    // Implement your logout logic here
    setAuthenticated(false);
    setUserLike([])
    setUserDisLike([])
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout,userLike,setUserLike,userDisLike,setUserDisLike }}>
      {children}
    </AuthContext.Provider>
  );
};