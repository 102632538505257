import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section
      className="contact padding footer text-center"
      id="contact"
      data-scroll-index="6"
    >
      <div className="container">
        <img
          src="/assets/img/logo.png"
          alt="header-Logo"
          className="logo mb-4"
        />
        <div className="footer_nav mt-3 mt-md-4">
          <a href="/#about" className="">
            About Us
          </a>
          <Link to="/members" className="">
            Members
          </Link>
          <a href="/#Tokenomics" className="">
            Tokenomics
          </a>
          <Link to="/gallery" className="">
            Assets Gallery
          </Link>
          <Link to="/elite-sciences" className="">
            Elite Sciences
          </Link>

          <a href="/#Contact" className="">
            Contact Us
          </a>
        </div>
        <div className="social-links mt-4">
          <ul>
            <li>
              <a target="_blank" href="#">
                <i className="fa fa-telegram"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="#">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="#">
                {" "}
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="#">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="copyright">&copy; 2024 FSAD369 All Rights Reserved</div>
    </section>
  );
}

export default Footer;
