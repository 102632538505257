import { addContactApi } from "../components/constant/api";
import { myApi } from "./api";

export const contactApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setContact: builder.mutation({
      query: (post) => ({
        url: addContactApi,
        method: "POST",
        body:post
      }),
    }),
    
  }),
});

export const {useSetContactMutation} = contactApi;