import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom"
// import { useGetHomeEliteScienceQuery } from "../../redux/eliteScienceApi";

function EliteScience() {
  // const {data}=useGetHomeEliteScienceQuery()
  const location = useLocation();
  const eliteRef = useRef(null);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#EliteScience":
        scrollToRef(eliteRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <div className=" p60  " id="EliteScience">
      <div className="container">
        <h2 className=" mb-4 hadding text-center">Elite Sciences</h2>
        <div className="row   align-items-center elite-p">
       <p>The FSAD369 Committee, consisting of experienced individuals and investors, is leading efforts to reshape the global economy by introducing the FSADUSD stablecoin. This currency is based on principles of transparency, stability, and inclusivity, and aims to facilitate value exchange and promote economic prosperity worldwide.</p> 
       <p>
       Therefore, it is crucial to develop the leaders and members of FSAD to effectively implement this system by providing them with training in what is called "Elite Sciences," or the Sciences of the Great, which encompass a set of knowledge aimed at achieving personal and spiritual advancement. These sciences focus on a deeper understanding of oneself and the world around us, including the study of philosophy, ethics, psychology, history, and more. They contribute to fostering tolerant and balanced perspectives and are considered tools for effective leadership and positive impact on society, enabling educated individuals to use their knowledge to improve individual and collective life in a sustainable and balanced manner.
       </p>
       <p>We will explore and discuss some of these sciences on our platform for clarification, and you can find topics of interest by clicking on the "Elite Sciences" button. We hope everyone benefits from this knowledge.
</p>
          {/* {data?.map((list)=>{
            return (
            <div className="col-md-6 ">
            <div className="elite_box bg_blar">
              <img src={list.image} className="img-fluid" alt=""/>
              <h4>{list.title}</h4>
              <p>
               {list.short_description}
              </p>
              <Link to={`/elite-sciences-detail/${list.id}`} className="btn">
                View More
              </Link>
            </div>
          </div>
            )
          })} */}
        </div>
        <div className="text-center mt-3">
          <Link className="btn btn-primary" to="/elite-sciences">Read More</Link>
        </div>
      </div>
    </div>
  );
}

export default EliteScience;
