import React from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLocation } from 'react-router-dom';
import { useSetEliteShareMutation } from '../../redux/eliteScienceApi';
import { toast } from 'react-toastify';

function ShareModal(props) {
    const location=useLocation()
    const [setEliteShare]=useSetEliteShareMutation()
    const handleClose=()=>{
        props.setShareShowModal(false)
    }
    const handleShare = (platform) => {
      
            let shareUrl = "https://fsad369.com"+location.pathname;
            if (platform === "whatsapp") {
              shareUrl = `https://wa.me/?text=${shareUrl}`;
            } else if (platform === "telegram") {
              shareUrl = `https://t.me/share/url?url=${shareUrl}`;
            } else if (platform === "twitter") {
              shareUrl = `https://twitter.com/intent/tweet?url=${shareUrl}`;
            }
        
            window.open(shareUrl, "_blank");
            const data={id:props.id}
            setEliteShare(data).then((result) => {
                if (result.data.status) {
                  handleClose()
                  props.handleSearch()
                  return false;
                } else {
                  toast.dismiss();
                  toast.error(result.data.message);
                  
                  return;
                }
              });
            
       
      };

  return (
    <>
    <Modal show={props.shareShowModal} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          {" "}
          <Modal.Title >Share Invitation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="mt-4 text-center">
                <button
                  className="share-btn"
                  onClick={() => handleShare("whatsapp")}
                >
                  <img src="/assets/img/whatsapp.png" style={{ width: "40px" }} />
                </button>
                <button
                  className="share-btn"
                  onClick={() => handleShare("telegram")}
                >
                  {/* <i className="bi bi-telegram" style={{ fontSize: "40px" }}></i> */}
                  <img src="/assets/img/tg.png" style={{ width: "40px" }} />
                </button>
                <button
                  className="share-btn"
                  onClick={() => handleShare("twitter")}
                >
                  <img src="/assets/img/twitter.png" style={{ width: "40px" }} />
                </button>
              </div>
        </Modal.Body>
        <Modal.Footer>
      
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
   
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ShareModal