import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSetEliteReplyMutation } from "../../redux/eliteScienceApi";
import { toast } from "react-toastify";

function EliteReplyModa(props) {
  const [setEliteReply] = useSetEliteReplyMutation();
  const [reply, setReply] = useState("");
  const [replyErr, setReplyErr] = useState("");
  const [disable, setDisable] = useState(false);

  const handleChange = (e) => {
    setReply(e.target.value);
    if (e.target.value == "") {
      setReplyErr("This field is required");
    } else {
      setReplyErr();
    }
  };
  const handleClose = () => {
    setReply("");
    setReplyErr("");
    setDisable(false)
    props.setShowModal(false)
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (reply == "") {
      setReplyErr("This field is required");
      return;
    }
    setDisable(true);
    const data = {
      elite_science_id: props.elite_science_id,
      reply_comment: reply,
      id: props.id,
    };
    setEliteReply(data).then((result) => {
        if (result.data.status) {
          toast.success(result.data.message);
          props.getEliteUserComment({id:props.elite_science_id})
          handleClose()
          return false;
        } else {
          toast.dismiss();
          toast.error(result.data.message);
          setDisable(false)
          return;
        }
      });
  };
  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title >Reply</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label className="form-label">Reply Message</label>
            <input
              className="form-control"
              value={reply}
              onChange={handleChange}
            />
            <span className="text-danger">{replyErr}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={onSubmit} disabled={disable}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EliteReplyModa;
