import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <header className="home" id="home" data-scroll-index="1">
      <div className="banner-bg-two"></div>

      <div className="banner_content text-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 ">
              <h3 className="mb-3">
                Welcome to The New Financial System: <br />
                <span>Enter the Golden Age!</span>
              </h3>
              <p>
                {" "}
                Experience the dawn of a new financial era: embrace the Quantum
                Financial System and the Global Currency Reset, transitioning
                from debt-driven fiat to gold-backed currencies, marking the
                shift from darkness to enlightenment.{" "}
              </p>
              <p>
                Explore the transition and transformative shift towards global
                prosperity with the Global Currency Reset (GCR) and the
                revolutionary Quantum Financial System (QFS)
              </p>

              <Link className="btn btn-action mt-3" to="/elite-sciences">
                Get Connected
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Banner;
