import React, { useEffect, useRef,useState } from "react";
import { useLocation } from "react-router-dom"
import { useSetContactMutation } from "../../redux/contactApi";
import { InputValid } from "../validations/InputValid";
import { toast } from "react-toastify";

function ContactUs() {
  const location = useLocation();
  const contactRef = useRef(null);
  const [setContact] = useSetContactMutation();
  const [captchaText, setCaptchaText] = useState("");
  const [userCaptch, setUserCaptch] = useState("");
  const [userCaptchErr, setUserCaptchErr] = useState("");
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectErr, setSubjectErr] = useState("");
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [rediency, setRediency] = useState("");
  const [rediencyErr, setRediencyErr] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberErr, setMobileNumberErr] = useState("");
  const [disable, setDisable] = useState(false)
  const canvasRef = useRef(null);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#Contact":
        scrollToRef(contactRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
  String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

const generateCaptchaText = () => {
  let captcha = "";
  for (let i = 0; i < 3; i++) {
    captcha += generateRandomChar(65, 90);
    captcha += generateRandomChar(97, 122);
    captcha += generateRandomChar(48, 57);
  }
  return captcha
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
};

const drawCaptchaOnCanvas = (ctx, captcha) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  const textColors = ["rgb(130,130,130)", "rgb(130,130,130)"];
  const letterSpace = 150 / captcha.length;
  for (let i = 0; i < captcha.length; i++) {
    const xInitialSpace = 25;
    ctx.font = "20px Roboto Mono";
    ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
    ctx.fillText(
      captcha[i],
      xInitialSpace + i * letterSpace,

      // Randomize Y position slightly
      Math.floor(Math.random() * 16 + 25),
      100
    );
  }
};

const initializeCaptcha = (ctx) => {
  setUserCaptch("");
  const newCaptcha = generateCaptchaText();
  setCaptchaText(newCaptcha);
  drawCaptchaOnCanvas(ctx, newCaptcha);
};


const handleChnage = (e) => {
  const { name, value } = e.target;

  if (name === "name") {
    setName(value);
    const err = InputValid(name, value);
    setNameErr(err);
  }
  if (name === "captch") {
    setUserCaptch(value);
    const err = InputValid(name, value);
    setUserCaptchErr(err);
  }
  if (name === "message") {
    setMessage(value);
    const err = InputValid(name, value);
    setMessageErr(err);
  }
  if (name === "subject") {
    setSubject(value);
    const err = InputValid(name, value);
    setSubjectErr(err);
  }
  if (name === "rediency") {
    setRediency(value);
    const err = InputValid(name, value);
    setRediencyErr(err);
  }
  if (name === "mobileNumber") {
    setMobileNumber(value);
    const err = InputValid(name, value);
    setMobileNumberErr(err);
  }
};
const handleReset=()=>{
  setMobileNumber("")
  setRediency("")
  setSubject("")
  setMessage("")
  setUserCaptch("")
  setName("")
}
const handleSubmit = (e) => {
  e.preventDefault();
  const checkName = InputValid("name", name);
  const checkRediency = InputValid("rediency", rediency);
  const checkMobileNumber = InputValid("mobileNumber", mobileNumber);
  const checkSubject = InputValid("subject", subject);
  const checkMessage = InputValid("message", message);
  const checkUserCaptch = InputValid("userCaptch", userCaptch);
  if (checkName) {
    setNameErr(checkName);
    return false;
  }
  if (checkRediency) {
    setRediencyErr(checkRediency);
    return false;
  }
  if (checkMobileNumber) {
    setMobileNumberErr(checkMobileNumber);
    return false;
  }
  if (checkSubject) {
    setSubjectErr(checkSubject);
    return false;
  }
  if (checkMessage) {
    setMessageErr(checkMessage);
    return false;
  }
  if (checkUserCaptch) {
    setUserCaptchErr(checkUserCaptch);
    return false;
  }
  if (userCaptch != captchaText) {
    setUserCaptchErr("Invalid captcha code");
    return false;
  }
  const data = {
    name: name,
    rediency: rediency,
    mobile_number: mobileNumber,
    subject: subject,
    message: message,
  };
  setContact(data).then((result) => {
    if (result.data.status) {
      toast.dismiss();
      toast.success(result.data.message);
      setDisable(false);
      initializeCaptcha(canvasRef.current.getContext("2d"))
      handleReset("")
    }
    else{
      toast.dismiss();
      toast.error(result.data.message);
    }
  });
};

  return (
    <div className="contact-form-wrap p60" id="Contact" ref={contactRef}>
      <div className="container ">
        <h2 className=" mb-4 hadding text-center">
          Contact <span>Us</span>{" "}
        </h2>
        <div className="big_box  bg_blar contact-form-wrap">
          <form onSubmit={handleSubmit}>
          <div className="row">
              <div className="col-md-6 ">
                <div className="form-group">
                  <label>Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" "
                    name="name"
                    onChange={handleChnage}
                    value={name}
                  />
                  <span className="text-danger">{nameErr}</span>
                </div>

                <div className="form-group">
                  <label>Rediency*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" "
                    name="rediency"
                    onChange={handleChnage}
                    value={rediency}
                  />
                  <span className="text-danger">{rediencyErr}</span>
                </div>
                <div className="form-group">
                  <label>Mobile Telephone Number*</label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder=""
                    name="mobileNumber"
                    onChange={handleChnage}
                    value={mobileNumber}
                  />
                  <span className="text-danger">{mobileNumberErr}</span>
                </div>
                <div className="form-group">
                  <label>Subject*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    onChange={handleChnage}
                    value={subject}
                  />
                  <span className="text-danger">{subjectErr}</span>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-group">
                  <label>Message*</label>
                  <textarea
                    className="form-control"
                    name="message"
                    onChange={handleChnage}
                    value={message}
                  ></textarea>
                  <span className="text-danger">{messageErr}</span>
                </div>
                <div className="form-group">
                  {/* <img
                  src="/assets/img/captcha.png"
                  alt="header-Logo"
                  className="captcha mb-2 h40"
                /> */}
                  <div className="wrapper">
                    <canvas ref={canvasRef} width="200" height="70"></canvas>
                    <button
                      id="reload-button"
                      className="btn btn-primary"
                      onClick={() =>
                        initializeCaptcha(canvasRef.current.getContext("2d"))
                      }
                      type="button"
                    >
                      Reload
                    </button>
                  </div>
                  <br />
                  <label>Enter Captcha Code*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="captch"
                    onChange={handleChnage}
                    value={userCaptch}
                  />
                  <span className="text-danger">{userCaptchErr}</span>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
